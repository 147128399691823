import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import ViewHeader from 'Components/layout/panel/ViewHeader';
import Editor from 'Components/admin/users/Editor';

export default class AdminUsersCreate extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    render() {
        const { location, history } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="admin-users-create"
                styles={require('./styles')}
            >
                <PageContent>
                    <ViewHeader
                        title="Nowy użytkownik"
                    />
                    <Editor
                        location={location}
                        history={history}
                        predefinedState={queryObject}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}